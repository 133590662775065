import * as React from "react";
import { graphql, HeadFC, Link, PageProps } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const PostPage: React.FC<PageProps<Queries.PostListByBookQuery>> = ({
  data,
}) => {
  console.log(data);
  return (
    <Layout>
      <h1>{data.microcmsBook?.title}を紹介している投稿一覧</h1>
      <ul>
        {data.allMicrocmsPosts.nodes.map((node) => {
          return (
            <li key={node.postsId}>
              <Link to={`/post/${node.postsId}`}>
                <span>{node.title}</span>
                <time>（公開日：{node.publishedAt}）</time>
                <span>{node.author}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query PostListByBook($bookId: String) {
    microcmsBook(bookId: { eq: $bookId }) {
      bookId
      afLink
      category
      img {
        url
      }
      link
      title
    }
    allMicrocmsPosts(filter: { book: { elemMatch: { id: { eq: $bookId } } } }) {
      nodes {
        title
        author
        publishedAt(formatString: "YYYY/MM/DD")
        postsId
      }
    }
  }
`;

export default PostPage;

export const Head: HeadFC<Queries.PostListByBookQuery> = ({ data }) => (
  <Seo title={`${data.microcmsBook?.title}を紹介している投稿一覧`} />
);
